<template>
  <div class="solution-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> vertical_split</span
          >Managed Services
        </h5>
        <router-link to="#" class="hilight-font"
          >With our Managed Services, offering procative monitoring, expert
          management, and seamless support to ensure your systems run smoothly
          and efficiently ​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> chromecast_device </span
          >IT Outsource Services
        </h5>
        <router-link to="#" class="hilight-font"
          >Streamline IT operations with our comprehensive IT Outsource
          Services, providing expert support, cost-effective and enhanced
          efficiency to drive your business forward.​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> devices_other</span
          >Enterprise Mobility
        </h5>
        <router-link to="#" class="hilight-font"
          >Enables secure, seamless access to business applications and data
          from any device and empowering your workforce to stay productive.
          ​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> lan</span>Network
        </h5>
        <router-link to="#" class="hilight-font"
          >Providing comprehensive network solutions to enhance connectivity,
          performance, and security for your business.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> contact_phone </span
          >Collaboration Solutions​​​​​
        </h5>
        <router-link to="#" class="hilight-font"
          >Enhance teamwork and communication with our innovative collaboration
          solutions, designed to streamline workflows and boost
          productivity.​​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> speed_camera </span
          >Video Surveilliance
        </h5>
        <router-link to="#" class="hilight-font"
          >Enhance security and monitor your premises with our advanced video
          surveillance systems, providing real-time monitoring and reliable
          protection.​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
