<template>
  <div class="solution-container">
    <div class="row mt-5">
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> api </span>
          ERP​
        </h5>
        <router-link to="#" class="hilight-font"
          >Streamline your operations with our robust ERP development services,
          integrating all your business processes into a unified
          system.​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> chromecast_device </span
          >IoT
        </h5>
        <router-link to="#" class="hilight-font"
          >Empowers businesses with real-time data collection, intelligent
          automatuon, driving efficiency, innovation through connected devices
          and smart solutions​​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> devices_other</span
          >Enterprise Mobility
        </h5>
        <router-link to="#" class="hilight-font"
          >Enables secure, seamless access to business applications and data
          from any device and empowering your workforce to stay productive.
          ​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> lan</span>Network
        </h5>
        <router-link to="#" class="hilight-font"
          >Providing comprehensive network solutions to enhance connectivity,
          performance, and security for your business.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> analytics </span>Data
          Analytics​
        </h5>
        <router-link to="#" class="hilight-font"
          >Unlocks valuable insights from your data, empowering smarter
          decisions, enhanced peformance, optimize operations and strategic
          business growth.​​​</router-link
        >
      </div>
      <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 40px">
        <h5 class="d-flex">
          <span class="material-symbols-outlined me-3"> speed_camera </span
          >Video Surveilliance
        </h5>
        <router-link to="#" class="hilight-font"
          >Enhance security and monitor your premises with our advanced video
          surveillance systems, providing real-time monitoring and reliable
          protection.​</router-link
        >
      </div>
    </div>
    <div class="readmore">
      <router-link to="#" class="d-flex align-items-center gap-2">
        <span>Read More Solution</span>
        <span class="material-symbols-outlined mt-1 ms-1" style="color: #ec6316"
          >arrow_right_alt</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
